/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
  background: black;
  height: 100vh;
  width: 100vw;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.instruments {
  height: 100vh;
  min-width: 520px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.instrument-name {
  padding-top: 1px;
}

.instrument {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: fit-content;
  height: 90px;
  border: solid 1px gray;
  padding: 1px;
  margin: 1px;
  font-family: "ibmvga";
  align-items: center;
}

.knobs {
  display: flex;
  align-items: top;
  flex-direction: row;
  justify-content: space-around;
  font-family: "ibmvga";
  width: max-content;
  border: solid 1px gray;
  padding: 2px;
  margin: 1px;
}

.settings {
  display: flex;
  align-items: top;
  flex-direction: column;
  justify-content: space-around;
  font-family: "ibmvga";
  width: max-content;
  border: solid 1px gray;
  padding: 2px;
  margin: 1px;
}

.info {
  border: solid 1px gray;
  position: absolute;
  font-family: "ibmvga";
  right: 0;
  bottom: 0;
  padding: 2px;
}

.select {
  appearance: none;
}

.settings-keybindings {
  color: white;
  background: black;
  font-family: "ibmvga";
}

.code {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  scrollbar-color: white black;
  scrollbar-width: thin;
  max-width: calc(100vw - 520px);
  order: 0;
}

.janet-err {
  font-family: "ibmvga";
  color: red;
  font-size: larger;
}

.output-channel {
  background: black;
  color: white;
  max-height: 20vh;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
	flex-direction: column-reverse;
}

.main-area {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.intro {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: black;
  cursor: pointer;
}

.intro-title {
}
.about-area {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background: black;
  font-family: "ibmvga";
  font-size: "large";
}

.about-area > p {
  margin: 1em;
}

a:link {
  color: white;
}

a:visited {
  color: white;
}

.about-area > div {
  margin: 1em;
  border-top: 1px solid white;
  width: 22rem;
}

.intro-clicked {
  display: none;
}
